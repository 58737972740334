import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import CurriculumItem from "../components/curriculum/curriculumItem";
import LanguageItem from "../components/curriculum/languageItem";
import ProjectCurriculum from "../components/curriculum/projectCurriculum";



import { useTranslation } from "react-i18next";



const Curriculum = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [t,i18n] = useTranslation("global")



	const educations = t("curriculum.educations", { returnObjects: true });
	const professional_experiences = t("curriculum.professional_experience", { returnObjects: true });
	const languages = t("curriculum.languages", { returnObjects: true });
	const awards = t("curriculum.awards", { returnObjects: true });
	const projects = t("curriculum.projects", { returnObjects: true });

	return (
		<React.Fragment>
			<Helmet>
				<title>{t("navbar.curriculum")}</title>


			</Helmet>

			<div className="page-content">
				
				<NavBar active={t("navbar.curriculum")} />
				
				<div className="content-wrapper">
					<div className="curriculum-logo-container">
						<div className="curriculum-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="curriculum-container">
						<div className="curriculum-main">
							<div className="curriculum-left-side">

								<div className="title curriculum-title">
									{t("curriculum.name")}
								</div>

								<div className="curriculum-item-separator"></div>

								
								{/* EDUCATION */}
								<div className="curriculum-section-title">
									
									{t("curriculum.education")}
								</div>

								<div className="curriculum-item-separator"></div>

								{educations.map((education, index) => (
										<CurriculumItem 
											logo={education.logo}
											title={education.title}
											description={education.description}

										/>
								))}


								{/* LANGUAGES */}
								<div className="curriculum-section-title">
									{t("curriculum.languages_title")}
								</div>

								{languages.map((language, index) => (
										<LanguageItem 
											title={language.title}
											level={language.level}

										/>
								))}

								

								<div className="curriculum-item-separator"></div>

								{/* AWARDS */}
								<div className="curriculum-section-title">
									{t("curriculum.awards_title")}
								</div>
								<div className="award-container">
									{awards.map((award, index) => (
											<div className="award-item">{award.title}</div>
									))}
								</div>




								<div className="curriculum-item-separator"></div>



							</div>
							

							<div className="curriculum-right-side">


								{/* PROFESSIONAL EXPERIENCE */}

								<div className="curriculum-section-title">
									{t("curriculum.professional_experience_title")}
								</div>
								
								{professional_experiences.map((experience, index) => (
										<CurriculumItem 
											logo={experience.logo}
											title={experience.title}
											description={experience.description}

										/>
								))}


								{/* PROJECTS */}
								<div className="curriculum-section-title">
									{t("curriculum.projects_title")}
								</div>

								{projects.map((project, index) => (
										<ProjectCurriculum 
											title={project.title}
											technologies={project.technologies}
											link_text={project.link_text}
											link={"/projects/" + (project.index)}

										/>
								))}

							</div>
						</div>

					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Curriculum;
