import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

import "./styles/socials.css";

const Socials = ({ linkedin_link, github_link, instagram_link, email }) => {
	return (
		<div className="socials">
			<div className="social">
				<a href={linkedin_link} target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faLinkedin} className="social-icon" />
					<div className="social-text">Linkedin</div>
				</a>
			</div>

			<div className="social">
				<a href={github_link} target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faGithub} className="social-icon" />
					<div className="social-text">GitHub</div>
				</a>
			</div>

			<div className="social">
				<a href={instagram_link} target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faInstagram} className="social-icon" />
					<div className="social-text">Instagram</div>
				</a>
			</div>

			<div className="email">
				<div className="email-wrapper">
					<a href={`mailto:${email}`} target="_blank" rel="noreferrer">
						<FontAwesomeIcon icon={faEnvelope} className="social-icon" />
						<div className="social-text">{email}</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Socials;
