import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/education.css";
import "./styles/curriculum.css"
import { Link } from "react-router-dom";
const ProjectCurriculum = (props) => {
    const {title, technologies,link_text,link} = props;
    return (
        <React.Fragment>
            <div className="curriculum-item-container">
                    <div className="curriculum-item-title">
                        {title}
                    </div>

                    <div className="curriculum-item-description">
                        {technologies}
                    </div>
                    <Link to={link}>
                        <div className="project-link">
                            <div className="project-link-text">{link_text}</div>
                                <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </Link>
                    
                
            </div>
            <div className="curriculum-item-separator"></div>
        </React.Fragment>

    )
    
};

export default ProjectCurriculum;