import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";
import { useTranslation } from "react-i18next";



import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	const [t,i18n] = useTranslation("global")

	return (
		<React.Fragment>
			<Helmet>
				<title>{t("navbar.projects")}</title>

			</Helmet>

			<div className="page-content">
				<NavBar active={t("navbar.projects")} />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							{t("projects.projects_title")}
						</div>

						<div className="subtitle projects-subtitle">
							{t("projects.projects_description")}
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
