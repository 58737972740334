import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";



import "./styles/about.css";
import { useTranslation } from "react-i18next";



const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [t,i18n] = useTranslation("global")



	return (
		<React.Fragment>
			<Helmet>
				<title>{t("navbar.about")}</title>
			</Helmet>

			<div className="page-content">
				
				<NavBar active={t("navbar.about")} />
				
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-left-side">
								<div className="title about-title">
									{t("about.title")}
								</div>

								<div className="subtitle about-subtitle">
									{t("about.description")}
								</div>
							</div>

							<div className="about-right-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="photo_about.jpg"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>

								<div className="about-socials">
									<Socials 
									linkedin_link={t("personal_information.linkedin_link")}
									github_link={t("personal_information.github_link")}
									instagram_link={t("personal_information.instagram_link")}
									email={t("personal_information.email")}

									/>
								</div>
							</div>
						</div>
						<div className="">
							<Socials 
							linkedin_link={t("personal_information.linkedin_link")}
							github_link={t("personal_information.github_link")}
							instagram_link={t("personal_information.instagram_link")}
							email={t("personal_information.email")}
							/>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
