import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import './styles/imageCarousel.css';
import { useState } from "react";




const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="carousel">
            <button onClick={goToPrevious} className="carousel-button">
                <img src="../left_arrow.png"></img>
            </button>
            <img src={images[currentIndex]} alt={`slide ${currentIndex}`} className="carousel-image" />
            <button  onClick={goToNext} className="carousel-button">
                <img src="../right_arrow.png"></img>
            </button>
        </div>
    );
};

export default ImageCarousel;
