import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./styles/education.css";
import "./styles/curriculum.css"
const CurriculumItem = (props) => {
    const { logo, title, description} = props;
    return (
        <React.Fragment>
            <div className="curriculum-item-container">
                <img src={logo} className="curriculum-item-logo"  />
                <div className="curriculum-item-title">
                    {title}
                </div>

                <div className="curriculum-item-description">
                    {description}
                </div>
            </div>
            <div className="curriculum-item-separator"></div>
        </React.Fragment>

    )
    
};

export default CurriculumItem;