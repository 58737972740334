import React from "react";
import { Link } from "react-router-dom";
import "./styles/return_button.css";
import { useTranslation } from "react-i18next";

const ReturnButton = (props) => {
    const { active } = props;
    const [t, i18n] = useTranslation("global");

    return (
        <React.Fragment>
            <Link to={"/"}>
                <div className="return-button-div">
                    <img
                        src="../back-button.png"
                        alt="back"
                        className="return-button-image"
                    />
                    {t('common.return_button.text')}
                </div>
            </Link>
        </React.Fragment>
    );
};

export default ReturnButton;
