import React from "react";

import Project from "./project";

import INFO from "../../translation/pt/global.json";


import "./styles/allProjects.css";
import { useTranslation } from "react-i18next";


const AllProjects = () => {
    const [t, i18n] = useTranslation("global");

    const projects = t("projects.projects", { returnObjects: true });

    return (
        <div className="all-projects-container">
            {projects.map((project, index) => (
                <div className="all-projects-project" key={index}>
                    <Project
                        logo={project.logo}
                        title={project.title}
                        description={project.brief_description}
                        tecnologies = {project.tecnologies}
                        images={project.images}
                        link_text={project.link_text}
                        link={"/projects/" + (index)}
                    />
                </div>
            ))}
        </div>
    );
};

export default AllProjects;
