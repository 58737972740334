import { Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Curriculum from "./pages/curriculum";
import Projects from "./pages/projects";
import ProjectPage from "./pages/projectPage";
import Notfound from "./pages/404";
import ImageGeneratorHome from "./pages/ImageGenerator/ImageGeneratorHome";

import "./app.css";

import LanguageDropdown from "./components/common/language_dropdown"
function App() {
	

	return (
		<div className="App">
			<LanguageDropdown/>
			<Routes>
				
				<Route path="/" element={<About />} />
				<Route path="/curriculum" element={<Curriculum />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/projects/:slug" element={<ProjectPage />} />
				<Route path="*" element={<Notfound />} />

				<Route path="/image_generator" element={<ImageGeneratorHome />} />


			</Routes>

		</div>
	);
}

export default App;
