import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./styles/ImageGeneratorHome.css";
import { useTranslation } from "react-i18next";
import ImageComponent from "../../components/ImageGenerator/home/image_component";
import ImageButton from "../../components/ImageGenerator/home/image_button";
import ReturnButton from "../../components/common/retun_button";

const ImageGeneratorHome = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [t, i18next] = useTranslation("global");
    const homeInfo = t("image_generator.home", { returnObjects: true });
    const url = "https://danielvenancio.pythonanywhere.com/get_image";
    //const url = "http://localhost:5000/get_image";
    
    const getImage = async (label) => {
        setLoading(true); // Set loading to true when request starts
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'label': label }),
            });
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            setImageSrc(imageUrl);
        } catch (error) {
            console.error('Error fetching the image:', error);
        } finally {
            setLoading(false); 
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{t("image_generator.home.header")}</title>
            </Helmet>
            <ReturnButton />
            <div className="home-title">
                {homeInfo.title}
            </div>
            <div className="home-subtitle">
                {homeInfo.subtitle}
            </div>
            <div className="">
                {loading ? (
                    <div className="div-spinner">
                        <div className="spinner"></div>
                    </div> 
                    
                ) : (
                    <ImageComponent
                        imageSrc={imageSrc}
                        initial_text={homeInfo.initial_image_text}
                    />
                )}
            </div>
            <div className="home-buttons-container">
                <div className="home-buttons">
                    {homeInfo.buttons.map((button, index) => (
                        <ImageButton
                            key={index} 
                            text={button.text}
                            label={button.label}
                            onClick={getImage}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ImageGeneratorHome;
