import React from "react";


import "./styles/language.css";
import "./styles/curriculum.css"
const LanguageItem = (props) => {
    const {title, level} = props;
    return (
        <React.Fragment>
            <div class="language-item">
                <div class="column left-column">
                    {title}
                </div>
                <div class="column right-column">
                    {level}
                </div>
            </div>
            <div className="curriculum-item-separator"></div>
        </React.Fragment>

    )
    
};

export default LanguageItem;