import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";


import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import ImageCarousel from "../components/projects/imageCarousel";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/projectPage.css";
import { useTranslation } from "react-i18next";



const ProjectPage = () => {
	const navigate = useNavigate();
	let { slug } = useParams();
	const [t,i18n] = useTranslation("global")

	const projects = t("projects.projects", { returnObjects: true });
	const project = projects[slug]



	useEffect(() => {
		window.scrollTo(0, 0);
	}, [project]);


	
	return (
		<React.Fragment>
			<Helmet>
				<title>{`${project.title}`}</title>
				<meta name="description" content={project.description} />

			</Helmet>

			<div className="page-content">
				<NavBar active={t("navbar.projects")} />

				<div className="content-wrapper">
					<div className="read-article-logo-container">
						<div className="read-article-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="read-article-container">
						<div className="read-article-back">
							<img
								src="../back-button.png"
								alt="back"
								className="read-article-back-button"
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="read-article-wrapper">
							<div className="read-article-date-container">
								<div className="read-article-date">
									{project.title}
								</div>
							</div>

							<div className="title read-article-title">
								{project.title}
							</div>

							<div className="read-article-body">
								{project.full_description}
							</div>
							<div className="project-tecnologies">
								{project.tecnologies}
							</div>
							<div className="project-link">
								<Link to={project.external_link}>
									<div className="project-link-text">{project.external_link_text}</div>
										{project.external_link_text && (
												<div className="project-link-icon">
													<FontAwesomeIcon icon={faArrowRight} />
												</div>
											
											
										)}
								</Link>
							</div>
							
							<div>
								<ImageCarousel images={project.images}/>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ProjectPage;
