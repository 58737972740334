import React from "react";
import { Link } from "react-router-dom";
import "./styles/navBar.css";
import { useTranslation } from "react-i18next";


const NavBar = (props) => {
	const { active } = props;
	const [t,i18n] = useTranslation("global")

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">

							
							<li
								className={
									active === t("navbar.about")
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">{t("navbar.about")}</Link>
							</li>

							<li
								className={
									active === t("navbar.curriculum")
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/curriculum">{t("navbar.curriculum")}</Link>
							</li>

							<li
								className={
									active === t("navbar.projects")
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects">{t("navbar.projects")}</Link>
							</li>

						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
